import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { DeliveryActionType, DeliveryStatusType, DeliveryStepStatusType, UserRoleType } from '../../../constants';
import { deliveryFlowStepListGlobal, deliveryStepList } from '../../../services/api';
import { replaceParameters } from '../../../utilies/localization';
import DeliveryItemSteps from './DeliveryItemSteps';

export default function DeliveryItemDetail({ user, country, delivery, list }) {
  // Definitions
  const { t } = useTranslation();

  const [steps, setSteps] = useState([]);
  const [flows, setFlows] = useState([]);

  const [stepFilter] = useState({
    country,
    pageNumber: 1,
    dataLimit: 1000,
    orderBy: ['row|asc'],
    deliveryId: delivery.id,
    targetUserId: delivery.targetUserId,
  });

  const [flowStepFilter] = useState({
    country,
    pageNumber: 1,
    dataLimit: 1000,
    orderBy: ['row|asc'],
    deliveryFlowId: delivery.deliveryFlowId,
  });

  // Hooks
  useEffect(() => {
    load();
  }, [delivery]);

  // Functions
  const load = async () => {
    let [deliveryStepResponse, deliveryFlowStepResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        deliveryStepList(stepFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        deliveryFlowStepListGlobal(flowStepFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (deliveryStepResponse?.data) {
      let currentSteps = deliveryStepResponse.data.map((x) => {
        x.creationTime = moment(x.creationTime);
        let parameters = {};

        if (x.action === DeliveryActionType.EnterDeliveryDate) {
          parameters = { ...parameters, 'Delivery.DeliveryDate': moment(JSON.parse(x.data)).add(user.t, 'minutes').format('ll') };
        }
        if (x.action === DeliveryActionType.EnterVisitDate) {
          parameters = { ...parameters, 'Delivery.VisitDate': moment(JSON.parse(x.data)).add(user.t, 'minutes').format('ll') };
        }

        x.nameKey = replaceParameters(t(x.nameKey), parameters);
        if (x.descriptionKey) x.descriptionKey = replaceParameters(t(x.descriptionKey), parameters);

        x.completedNameKey = replaceParameters(t(x.completedNameKey), parameters);
        if (x.completedDescriptionKey) x.completedDescriptionKey = replaceParameters(t(x.completedDescriptionKey), parameters);
        return x;
      });

      if (delivery.status === DeliveryStatusType.Rejected) {
        currentSteps.push({ nameKey: t('DeliveryRejected'), creationTime: moment(delivery.modificationTime) });
      }

      setSteps(currentSteps);
    }

    if (deliveryFlowStepResponse?.data) {
      setFlows(deliveryFlowStepResponse.data);
    }
  };

  // Events
  const onCompleted = () => {
    load();
    list();
  };

  // Render
  return (
    <Container>
      <Row gutter={24}>
        <Col key="detail" xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title>{t('DeliveryDetail')}</Title>

          <Details>
            <Row gutter={24}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Detail>
                  <Name>{t('Reference')}</Name>
                  <Value>{delivery.reference}</Value>
                </Detail>
              </Col>
              {delivery.freeBottle !== null && (
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Detail>
                    <Name>{t('FreeBottle')}</Name>
                    <Value>{delivery.freeBottle}</Value>
                  </Detail>
                </Col>
              )}

              {delivery.paidBottle !== null && (
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Detail>
                    <Name>{t('PaidBottle')}</Name>
                    <Value>{delivery.paidBottle}</Value>
                  </Detail>
                </Col>
              )}

              {delivery.totalBottle !== null && (
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <Detail>
                    <Name>{t('TotalBottle')}</Name>
                    <Value>{delivery.totalBottle}</Value>
                  </Detail>
                </Col>
              )}
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Detail>
                  <Name>{t('DeliveryFlow')}</Name>
                  <Value>{t(delivery.deliveryFlow.nameKey)}</Value>
                </Detail>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Detail>
                  <Name>{t('CurrentStep')}</Name>
                  <Value>
                    {delivery.status !== DeliveryStatusType.Rejected
                      ? t(delivery.currentStep.status === DeliveryStepStatusType.Approved ? delivery.currentStep.completedNameKey : delivery.currentStep.nameKey)
                      : t('Rejected')}
                  </Value>
                </Detail>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Detail>
                  <Name>{t('DeliverySender')}</Name>
                  <Value>{delivery.user.fullName ?? delivery.user.reference}</Value>
                </Detail>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Detail>
                  <Name>{t('DeliveryRecipient')}</Name>
                  <Value>{delivery.targetUser.fullName ?? delivery.targetUser.reference}</Value>
                </Detail>
              </Col>
            </Row>

            {delivery.targetUser.fullName && (
              <Detail last={!delivery.note}>
                <Name>{t('Address')}</Name>
                <Value>
                  {delivery.targetUserAddress} ({delivery.targetUser.city.name})
                </Value>
              </Detail>
            )}
            {delivery.note && user.u !== UserRoleType.Patient && (
              <Detail last={delivery.note}>
                <Name>{t('Note')}</Name>
                <Value>{delivery.note}</Value>
              </Detail>
            )}
          </Details>
        </Col>
        <Col key="steps" xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title>{t('DeliverySteps')}</Title>

          <DeliveryItemSteps country={country} delivery={delivery} steps={steps} flows={flows} onCompleted={onCompleted} />
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  padding: 8px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Details = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-bottom: 24px;
  }
`;

const Detail = styled.div`
  margin-bottom: 12px;

  ${({ last }) =>
    last &&
    css`
      margin-bottom: 0px;
    `}
`;
const Name = styled.div`
  font-weight: bold;
`;

const Value = styled.div``;
