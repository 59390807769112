const RoleColumnTypes = [
  {
    value: 0,
    name: 'Reference',
    displayName: 'Reference',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 100,
    data: {}
  },
  {
    value: 1,
    name: 'Drug',
    displayName: 'Drug',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 200,
    data: {}
  },
  {
    value: 2,
    name: 'DailyDoseMg',
    displayName: 'DailyDoseMg',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 300,
    data: {}
  },
  {
    value: 3,
    name: 'DailyDoseMl',
    displayName: 'DailyDoseMl',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 400,
    data: {}
  },
  {
    value: 4,
    name: 'DrugAmount',
    displayName: 'DrugAmount',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 500,
    data: {}
  },
  {
    value: 5,
    name: 'TotalBottle',
    displayName: 'TotalBottle',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 600,
    data: {}
  },
  {
    value: 6,
    name: 'PreparedBottle',
    displayName: 'PreparedBottle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 700,
    data: {}
  },
  {
    value: 7,
    name: 'UserFullName',
    displayName: 'UserFullName',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 800,
    data: {}
  },
  {
    value: 8,
    name: 'UserReference',
    displayName: 'UserReference',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 900,
    data: {}
  },
  {
    value: 9,
    name: 'StartDate',
    displayName: 'StartDate',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1000,
    data: {}
  },
  {
    value: 10,
    name: 'EndDate',
    displayName: 'EndDate',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1100,
    data: {}
  },
  {
    value: 11,
    name: 'Duration',
    displayName: 'Duration',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 1200,
    data: {}
  },
  {
    value: 12,
    name: 'BatchNumber',
    displayName: 'BatchNumber',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1300,
    data: {}
  },
  {
    value: 13,
    name: 'Status',
    displayName: 'Status',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1400,
    data: {}
  },
  {
    value: 14,
    name: 'DeliveryFlow',
    displayName: 'DeliveryFlow',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1500,
    data: {}
  },
  {
    value: 15,
    name: 'CurrentStep',
    displayName: 'CurrentStep',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1600,
    data: {}
  },
  {
    value: 16,
    name: 'Sender',
    displayName: 'DeliverySender',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1700,
    data: {}
  },
  {
    value: 17,
    name: 'Recipient',
    displayName: 'DeliveryRecipient',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1800,
    data: {}
  },
  {
    value: 18,
    name: 'DeliveredDate',
    displayName: 'DeliveredDate',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 1900,
    data: {}
  },
  {
    value: 19,
    name: 'CreationTime',
    displayName: 'CreationTime',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 2000,
    data: {}
  },
  {
    value: 20,
    name: 'ModificationTime',
    displayName: 'ModificationTime',
    group: 'Treatment,Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 2100,
    data: {}
  },
  {
    value: 21,
    name: 'ExternalId',
    displayName: 'ExternalId',
    group: 'Delivery',
    shortName: null,
    description: null,
    prompt: null,
    order: 2200,
    data: {}
  },
  {
    value: 22,
    name: 'DeliveryReference',
    displayName: 'DeliveryReference',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 2300,
    data: {}
  },
  {
    value: 23,
    name: 'Cycle',
    displayName: 'Cycle',
    group: 'Treatment',
    shortName: null,
    description: null,
    prompt: null,
    order: 2400,
    data: {}
  }
];

export default RoleColumnTypes;