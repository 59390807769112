import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

export default function BiaxialBarChartDetail({ data }) {
  return data ? (
    <Container>
      <Header>{data.label}</Header>
      <Details>
        {data.items.map((x, i) => (
          <Detail key={`detail-${i}`}>
            <Name>{x.dataKey}</Name>
            <Point>{x.value}</Point>
          </Detail>
        ))}
      </Details>
    </Container>
  ) : (
    <Skeleton />
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;
const Header = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;
const Details = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
`;
const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #02e3ab !important;
  padding: 5px;
  border-radius: 8px;
`;
const Name = styled.div`
  font-size: 12px;
`;
const Point = styled.span`
  font-weight: bold;
  font-size: 12px;
`;
