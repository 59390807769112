import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { useLocation } from 'react-router';
import ContentUserColumns from '../../../constants/columns/ContentUserColumns';
import { useCacheUser } from '../../../hooks/useCacheUser';
import { contentUserList, contentUserMyList } from '../../../services/api';
import { Card, Link, Modal, Table } from '../../globals';
import ContentPreview from '../content/ContentPreview';

export default function ContentUserList({ parameter, loaded }) {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const [user] = useCacheUser();

  const [response, setResponse] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewWidth, setPreviewWidth] = useState('50%');

  const [panelVisibility, setPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({
    list: true,
    form: true,
    delete: true,
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    dataLimit: 10,
    orderBy: ['modificationTime|desc'],
    userId: parameter.id,
    isMyData: parameter.isMyData,
    includeParentContent: true,
    country: parseInt(user.c),
    userRole: parameter.userRole,
  });

  // Hooks
  useEffect(() => {
    list();
  }, [filter]);

  // Functions
  const list = async () => {
    setPageState((x) => ({ ...x, list: false }));

    let request = filter.isMyData ? contentUserMyList : contentUserList;

    await request(filter, (status, res) => {
      if (status) {
        setResponse(res);
      }

      if (loaded) {
        loaded(res);
      }

      setPageState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onSeeDetailClick = (row) => {
    setPreview(row);
    setPanelVisibility(true);
  };

  const onCancelClick = () => {
    setPreview(null);
    setPanelVisibility(false);
  };

  const onWidthChange = (value) => {
    setPreviewWidth(value < 2 ? '50%' : value === 5 ? '80%' : '40%');
  };

  return (
    <Card
      ready={pageState.list}
      templates={['widget', 'with-header', 'header-information', 'header-colored', 'full-width', 'visit']}
      information={t('ContentReportsInformation')}
      title={t('ContentReports')}
      right={
        !location.pathname.includes('journey') && (
          <Link to="/journey">
            <FaCaretRight />
          </Link>
        )
      }
    >
      <Table ready={pageState.list} response={response} filter={filter} setFilter={setFilter} columns={ContentUserColumns({ onSeeDetailClick })} scroll={700} />

      <Modal width={previewWidth} title={t('ContentPreview')} visible={panelVisibility} onCancelClick={onCancelClick} templates={['colored']} footer={null}>
        {preview && <ContentPreview parameter={parameter} id={preview.parentContent.id} period={preview.period} onWidthChange={onWidthChange} />}
      </Modal>
    </Card>
  );
}
