import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import theme from '../../../styles/theme';

export default function BiaxialBarChart({ data, groups, onUpdateTooltipContent }) {
  const tooltipContent = ({ active, payload, label }) => {
    if (active && payload && payload.length && onUpdateTooltipContent) {
      onUpdateTooltipContent({ label, items: payload });

      return null;
    }
    return null;
  };

  return (
    <BarChart
      width={800}
      height={500}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" angle={-45} dx={0} dy={0} textAnchor="end" minTickGap={-500} height={120} fontSize={10} />
      <YAxis yAxisId="left" orientation="left" stroke={theme.colors.deepKaomaru} />
      <YAxis yAxisId="right" orientation="right" stroke={theme.colors.deepKaomaru} />
      <Tooltip content={tooltipContent} cursor={{ fill: theme.colors.antiFlashWhiteDark }} />

      {groups.map((x, i) => (
        <Bar key={`bar-${i}`} dataKey={x} yAxisId="left">
          {data.map((y, t) => (
            <Cell key={`cell-${t}`} fill={groups.length - 1 === i ? y.color ?? theme.colors.robinEggBlue : theme.colors.score3} />
          ))}
          <LabelList dataKey={x} position="top" fontSize="8" />
        </Bar>
      ))}
    </BarChart>
  );
}
